import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from './Layouts/navbar';
import Footer from './Layouts/footer';
import Main from './main';
import SpaceBowling from './component/space-bowling';
import Billard from './component/billard';
import LaserGame from './component/laser-game';
import Anniversaire from './component/Anniversaire';
import NosTarifs from './component/nos-tarifs';
import ContactezNous from './component/contactez-nous';
import Restaurant from './component/restaurant';
import Conditions from './component/cms/conditions';
import SocietesGroupes from './component/cms/societes-groupes';
import NotFound from './component/notFound/notFound';
import PopupQuiz from './component/popups/popup-quiz';
import QuizBoxing from './component/quiz-boxing';

const App = () => {
  return (
    <section>
      {window.location.pathname === '/' ?
        <Router>
          <PopupQuiz />
          <Routes>
            <Route path="/" element={<Main />} exact />
            <Route element={<NotFound />} />
          </Routes>
        </Router> :
        <Router>
          <PopupQuiz />
          <NavBar />
          <Routes>
            <Route path="/space-bowling" element={<SpaceBowling />} exact />
            <Route path="/quiz-boxing" element={<QuizBoxing />} exact />
            <Route path="/billard" element={<Billard />} exact />
            <Route path="/anniversaire" element={<Anniversaire />} exact />
            <Route path="/laser-game" element={<LaserGame />} exact />
            <Route path="/nos-tarifs" element={<NosTarifs />} exact />
            <Route path="/contactez-nous" element={<ContactezNous />} exact />
            <Route path="/restaurant" element={<Restaurant />} exact />
            <Route path="/conditions" element={<Conditions />} exact />
            <Route path="/societes-groupes" element={<SocietesGroupes />} exact />
            <Route path='/*' element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      }

    </section>
  )
}
export default App
