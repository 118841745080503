import React from "react";
import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Restaurant = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Restaurant - Space Bowling</title>
        <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
      </Helmet>
      <Container>
        <Body>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div style={{ textAlign: "center" }}>
            <img src="images/restaurant/benz.png" className="image" alt="Benz" />
          </div>
          <div className="empty_space"></div>
          <div className="row">
            <div className="col-md-8 col-12">
              <img src="images/restaurant/242677941_3629502383819019_5157557712614866583_n.jpg" className="image" alt="diner" />
            </div>
            <div className="col-md-4 col-12">
              <h1 className="title">Ben's Diner Snack.</h1>
              <p>Envie de profiter d'un délicieux burger avant ou aprés votre partie de bowling ?<br />

                Découvrez notre espace BEN'S DINER en format Snack ! Tous les ingrédients sont réunis pour vous proposer une carte façon US DINER 100% authentique !<br />
                BURGERS, HOT-DOGS mais aussi des TAPAS et un COIN SUCRÉ pour les plus gourmands !</p>
              <a href="https://reservation.spacebowling.fr/storage/files/1/CARTE-BENS-2022.pdf" target="_blank" rel="noopener noreferrer" ><button className="submit">Découvrir La Carte</button></a>
            </div>
          </div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
        </Body>
      </Container>
    </HelmetProvider>
  )
}

const Container = styled.div`
  >.map{
    width: 100%;
    height: 450px;
  }
`;

const Body = styled.div`
  padding: 0 10%;
  @media (min-width: 1100px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: 1000px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  >div >.image{
    width: 100%;
    max-width: 400px ;
  }
  >.row {
    margin-right: 15px;
    margin-left: 15px;
  }

  >.row >div {
    margin: 25px 0;
    color: #000;
    font-family: Montserrat;
    font-size:15px;
    >.title{
      font-family: "Oswald";
      color: #014983;
      letter-spacing: .02em;
      font-size: 24px;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
      text-transform: uppercase;
    }
    >.image{
      width: 100%;
    }
    >a >.submit{
      color: #fff;
      border-color: #f6202c;
      background-color: #f6202c;
      font-size: 14px;
      padding: 14px 20px;
      border-radius: 3px;
      line-height: normal;
      border: 1px solid transparent;
    }
  }
`

export default Restaurant