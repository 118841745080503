import React from "react";
import styled from "styled-components"
import { Helmet, HelmetProvider } from "react-helmet-async";

const SpaceBowling = () => {

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Anniversaire - Space Bowling</title>
          <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
        </Helmet>
        <Header>
          <img src="/images/spaceBowling/header-apropos-1.png" alt="header" />
        </Header>
        <Container className="container-fluid">
          <Body>
            <div className="empty_space"></div>
            <div className="title">
              <p className="titlePart1">Space</p>
              <h1 className="titlePart2">Bowling</h1>
            </div>
            <div className="empty_space"></div>
            <div className="empty_space"></div>

            <div className="row">
              <div className="col-xl-6">
                <div className="row part1">
                  <div className="col-xl-12 col-md-8 col-12">
                    <img className="image  pb-4" src="/images/spaceBowling/SPACE-BOWLING.jpg" alt="SPACE-BOWLING" />
                  </div>
                  <div className="col-xl-12 col-md-4 col-12">
                    <h2>Notre espace</h2>
                    <p>
                      Dans le Sud, à Nîmes, le complexe de loisirs Space Bowling vous ouvre ses portes tout au long de l’année et vous réserve de nombreuses surprises. Notre espace de 2500m², comprenant 20 pistes de bowling, un laser game, des billards, une salle d’arcade, un bar et un diner américain a été totalement repensé pour vous faire vivre une Nouvelle Expérience.
                    </p>
                  </div>
                </div>
                <div className="empty_space"></div>
                <div className="empty_space show"></div>
              </div>
              <div className="col-xl-6">
                <div className="row part2">
                  <div className="col-xl-12 col-md-4 col-12">
                    <h2>DÉTENDEZ-VOUS.</h2>
                    <p>
                      Alors, en famille, entre amis ou entre collègues, venez découvrir les univers magiques du complexe de loisirs Space bowling. Enfin, faites de votre passage un moment inoubliable en profitant de nombreuses animations.
                    </p>
                  </div>
                  <div className="col-xl-12 col-md-8 col-12">
                    <img className="image pb-4" src="/images/spaceBowling/a-propos.png" alt="a-propos" />
                  </div>
                </div>
                <div className="empty_space"></div>
                <div className="empty_space show"></div>
              </div>
            </div>
          </Body>
        </Container>
      </div>
    </HelmetProvider>
  )
}

const Header = styled.div`
position: relative;
text-align: center;
  >img{
    width: 100%;
    @media (max-width: 708px) {
      width: auto;
      height: 150px;
      position: absolute;
      right: 0;
    }
  }`

const Container = styled.div`
  @media (min-width: 1400px) {
    width: 1100px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  @media (max-width: 1000px) and (min-width: 768px) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  @media (max-width: 708px) {
    padding-top: 150px;
  }
`;


const Body = styled.div`
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: right;
    @media (max-width:769px) {
      padding-right: 60px; 
    }
    @media (max-width: 768px) {
      padding-right: 0; 
    }
    >.titlePart1{
      font-family: Quentin,script;
      color: #1d4684;
      font-size: 60px;
      position:relative;
      top: 12px;
      font-weight: 600;
      margin:0;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
    >.titlePart2{
      font-size:70px;
      font-weight:900;
      margin:0;
      color: #e23726;
      font-family: Oswald, sans-serif;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.row >div >div >div {
    >h2{
    color: #014983;
    margin: 0;
    font-size: 25px;
    line-height: 33px;
    }
    >.image{
      width: 100%;
    }
  }
  >.part1{
    padding-top:100px
  }

  >.part2{
    padding-bottom:70px;
  }

  @media (min-width: 1200px) {
    .row.part2{
      flex-direction: column-reverse;;
    }
  }

  @media (max-width: 767px) {
    .row.part2{
      flex-direction: column-reverse;;
    }
    >.row >div >.empty_space.show{
      display: none;
    }
  }
`

export default SpaceBowling