import React from "react";
import styled from "styled-components"
import { Helmet, HelmetProvider } from "react-helmet-async";

const LaserGame = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Laser Game - Space Bowling</title>
          <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
        </Helmet>
        <div className="container-fluid" >
          <Body>
            <div className="title">
              <p className="titlePart1">Wines</p>
              <h1 className="titlePart2">LASER GAME</h1>
            </div>

            <div className="contents">
              <p>Décrouvrez le nouveau laser game DELTA STRIKE du complexe SPACE BOWLING, un espace particulier avec son labyrinthe pensé pour les fans de stratégie d’opérations commandos ou les snippers.</p>
              <p>Nous vous proposons un divertissement inédit et vous invitons à partager une expérience inoubliable. Il est possible de jouer jusqu’à 16 personnes à la fois, divisés en deux ou trois équipes, ou en solo.</p>
              <p>Vous êtes équipé d’un gilet électronique et d’un pistolet à rayons laser. Votre objectif sera de faire un maximum de points en touchant vos adversaires et en attaquant les bases adverses.</p>
              <p>Pendant le jeu, les scores pourront être suivis en direct par les personnes présentes dans l’accueil.</p>
              <div className="empty_space"></div>
              <div className="row div-magazone">
                <div className="inner col-xl-6 col-md-6 col-auto">
                  <h2 className="title">
                  DELTA STRIKE</h2>
                  <p className="text">
                    Laser Games </p>
                </div>
                {/* <div className="col-auto logo">
                  <img src="images/laserGame/laser-game.png" alt="laser-game" />
                </div> */}
              </div>
            </div>
          </Body>
        </div>
        <Images>
          <div className="row">
            <div className="col-6">
              <img className="blog1" src="/images/laserGame/game1.png" alt="game1" />
            </div>
            <div className="col-6 row">
              <div className="col-6">
                <img className="blog2" src="/images/laserGame/game2.png" alt="game2" />
              </div>
              <div className="col-6">
                <img className="blog3" src="/images/laserGame/game3.png" alt="game3" />
                <img className="blog3" src="/images/laserGame/game4.png" alt="game4" />
              </div>
            </div>
          </div>
        </Images>
      </div>
    </HelmetProvider>

  )
}

const Body = styled.div`
  padding-top:80px;
  padding-bottom:70px;
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: right;
    padding-bottom:80px;
    >.titlePart1{
      font-family: Quentin,script;
      color: #1d4684;
      font-size: 60px;
      position:relative;
      top: 12px;
      font-weight: 600;
      margin:0;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
    >.titlePart2{
      font-size:70px;
      font-weight:900;
      margin:0;      
      font-family: "Oswald",sans-serif;
      letter-spacing: .02em;
      text-transform: uppercase;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.contents{
    >.div-magazone{
      text-align: center;
      >.inner {
        text-align: right;
        >.title{
          display: block;
          margin: 0;
          color: #0a0a0a;
          font-size: 60px;
          line-height: normal;
        }
        >.text{
          display: block;
          color: #b2b2b2;
          font-size: 22px;
          font-weight: 600;
          margin: 0;
          margin-top: -18px;
        }
      }
      >.logo{
        text-align: left;
        padding-top: 20px;
      }
    }
  } 
  @media (max-width:768px) {
    padding-top:30px;
    >.title{
      padding-bottom:50px;
    }
  }
`

const Images = styled.div`
  >.row {
    margin: 0;
    padding: 0;
    >div{
       padding: 0;
       margin: 0;
      >div{
        padding: 0;
        >.blog2{
          padding:0 1px;
        }
       }
      >.blog1, >div .blog2{
        width: 100%;
        height: 100%;
      }

      >div .blog3{
        width: 100%;
        height: 50%;
        &:first-child{
          padding-bottom: 1px;
        }
    }   
  }
  }
`

export default LaserGame;