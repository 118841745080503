import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Helmet, HelmetProvider } from "react-helmet-async";
import $ from 'jquery';
import './modulePagePilling/jquery.pagepiling.css';
import './modulePagePilling/jquery.pagepiling.js';
import Agenda from "./agenda";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: true,
      classNameNavBar: "nav",
      inputRefEmail: "",
      error: " ",
      validEmail: /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/,
      success: ""

    }
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#pagepiling').pagepiling();
    });
  }

  openMenu() {
    this.setState({ classNameNavBar: "nav show" });
    this.setState({ showMenu: false });
  }

  closeMenu() {
    this.setState({ classNameNavBar: "nav" });
    this.setState({ showMenu: true });
  }

  handleOnChange = event => {
    this.setState({
      inputRefEmail: event.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault()
    const { validEmail, error } = this.state;
    var errorTest = error

    if (this.state.inputRefEmail === "") {
      errorTest = "Email est Obligatoire"
    } else if (!validEmail.test(this.state.inputRefEmail)) {
      errorTest = "Email est incorect"
    } else {
      errorTest = ""
    }

    if (errorTest === "") {
      this.setState({ inputRefEmail: "" });
      this.setState({ success: "Inscription réussie" })
    } else this.setState({ success: "" })

    this.setState({ error: errorTest })
  }


  render() {
    const classNameNavBar = this.state.classNameNavBar;
    const showMenu = this.state.showMenu;
    const openMenu = this.openMenu;
    const closeMenu = this.closeMenu;
    // const error = this.state.error;
    // const success = this.state.success;
    // const handleSubmit = this.handleSubmit;
    // const handleOnChange = this.handleOnChange;
    // const inputRefEmail = this.state.inputRefEmail;

    return (
      <HelmetProvider>
        <Helmet>
          <title>Space Bowling</title>
          <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
        </Helmet>
        <Body>
          <a href="/">
            <img src="/images/logo.png" alt="logo" className="logo" />
          </a>
          <div className={`${classNameNavBar}`}>
            {(showMenu) && <>
              <FontAwesomeIcon icon={faBars} className="icon" onClick={() => openMenu()} />
              <a href="https://reservation.spacebowling.fr/" target="_blank" rel="noopener noreferrer" className="reserve"><img src="./images/navbar/Path 14.svg" alt="" /> <p>je réserve ici</p></a>
            </>
            }
            {!showMenu && <FontAwesomeIcon icon={faXmark} className="icon iconX" onClick={() => closeMenu()} />}
            <ul className="menu-tablette" >
              <li style={{ animationDelay: "50ms" }}><a href="/space-bowling" className="item_text">SPACE BOWLING</a></li>
              <li style={{ animationDelay: "100ms" }}><a href="/quiz-boxing"  className="item_text">QUIZ BOXING</a></li>
              <li style={{ animationDelay: "150ms" }}><a href="/billard" className="item_text">BILLARD</a></li>
              <li style={{ animationDelay: "200ms" }}><a href="/anniversaire" className="item_text">ANNIVERSAIRE</a></li>
              <li style={{ animationDelay: "250ms" }}><a href="/laser-game" className="item_text">LASER GAME</a></li>
              <li style={{ animationDelay: "300ms" }}><a href="/nos-tarifs" className="item_text">NOS TARIFS</a></li>
              <li style={{ animationDelay: "350ms" }}><a href="/contactez-nous" className="item_text">CONTACTEZ-NOUS</a></li>
              <li style={{ animationDelay: "400ms" }} className="reserve-visibility">
                <a href="https://reservation.spacebowling.fr/" target="_blank" rel="noopener noreferrer" className="reserve"><img src="./images/navbar/Path 14.svg" alt="" /> <p>je réserve ici</p></a>
              </li>
            </ul>
          </div>
          <div id="pagepiling">
            <div className="section sec1">
              <div className="description">
                <p>Welcome</p>
                <h1>SPACE <br /> BOWLING</h1>
                <a href="/space-bowling">Voir Plus</a>
              </div>
            </div>
            <div className="section sec7">
              <div className="description quiz">
                <p>Quiz </p>
                <h2>Boxing</h2>
                <a href="/quiz-boxing">Voir Plus</a>
              </div>
            </div>
            <div className="section sec2">
              <div className="description restaurant">
                <p>VIP </p>
                <h2>Karaoké</h2>
                <a href="/space-bowling">Voir Plus</a>
              </div>
            </div>
            <div className="section sec3">
              <div className="description billard">
                <p>Tables de</p>
                <h2>billard</h2>
                <a href="/billard">Voir Plus</a>
              </div>
            </div>
            <div className="section sec4">
              <div className="description anniversaire">
                <p>Réservation </p>
                <h2>Anniversaire </h2>
                <a href="/anniversaire">Voir Plus</a>
              </div>
            </div>
            <div className="section sec5">
              <div className="description laser">
                <p>Delta strike </p>
                <h2>Lazer <br /> Game</h2>
                <a href="/laser-game">Voir Plus</a>
              </div>
            </div>
            <div className="section sectionAgenda sec6">
              <Agenda />
            </div>
          </div>
        </Body>
      </HelmetProvider>
    )
  }
}

const Body = styled.div`
  overflow:hidden;
  >a >.logo{
    position: absolute;
    top: 9px;
    left: 50px;
    z-index: 300;
    height: 95px;
    @media (max-width:768px) {
      top:6px;
      height: 75px;
    }
    @media (max-width: 600px) {
      left: 30px;
    }
  }
  .nav{
    font-size: 30px;
    >.menu-tablette{
      display: none;
      height: 100%;
      list-style-type: none;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin: 0;
      >li{
        padding-bottom: 30px;
        transform: translateY(0px);
        animation-name: navbarAnimation;
        transition: transform 150s;
        animation-duration: 3s;
        @keyframes navbarAnimation {
          from {
            transform: translateY(50px);
            opacity: 0;
            visibility: hidden;
          }
          to {
            transform: translateY(0px);
            opacity: 1 !important;
            visibility: visible;
          }
        }
        >.item_text{
          position: relative;
          color: white;
        }
      }
    }
    >.icon{
      position: absolute;
      right: 50px;
      top: 30px;
      width: 40px;
      z-index: 300;
      color: white;
      font-size: 40px;
      cursor: pointer;
      @media (max-width: 600px) {
        right: 30px;
      }
    }
    .reserve{
      color: white;
      background: black;
      position: absolute;
      top: 27px;
      right: 116px;
      font-size: 16px;
      font-weight: bold;
      font-family: "montserrat",sans-serif;
      text-transform: uppercase;
      display: flex;
      border: 3px solid #F6202C;
      border-radius: 6px;
      padding: 0 12px;
      z-index: 10;
      @media (max-width:460px) {
        display: none;
      }
      &:hover{
      >img{
        filter: invert(28%) sepia(62%) saturate(5185%) hue-rotate(342deg) brightness(93%) contrast(108%);
      }
      >p{
        color: #F6202C;
      }
    }
      >img{
        padding-right: 8px;
        filter: invert(96%) sepia(0%) saturate(4833%) hue-rotate(308deg) brightness(140%) contrast(100%);
      }
      >p{
        margin: 8px 0;
      }
    } 
    >.iconX{
      top: 25px;
      font-size: 50px;
    }
  }
  .nav.show{
    display: block;
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;    
    width: 100%;
    height: 100%;
    z-index: 110;
    >.menu-tablette{
      display: flex;
      align-items: center;
      @media (min-width: 461px) {
        .reserve-visibility{
          display: none;
        }        
      }
      .reserve{
        display: flex !important;
        position: relative;
        top: auto;
        right: auto;
        width: fit-content;
        >p{
          font-size: 26px;
          font-weight: 400;
          padding: 6px;
        }
      }
    }
  }
  
  >#pagepiling{
    >.sec1{
      background-image: url("./images/homePage/BG-spacebowling.jpg");
    }
    >.sec2{
      background-image: url("./images/homePage/VIP-Karaoke.jpg");
    }
    >.sec3{
      background-image: url("./images/homePage/home2.png");
    }
    >.sec4{
      background-image: url("./images/homePage/home3.png");
    }
    >.sec5{
      background-image: url("./images/homePage/home4.png");
    }
    >.sec7{
      background-image: url("./images/homePage/Quiz Boxing.png");
    } 
    >.sec6{
      background-image: url("./images/homePage/Sans-titre-1.jpg");
    } 
    >.section.active{
      >div >.description{
        >p{
          transform: translateY(0px);
          animation-name: h1Animation;
          transition: transform 150s;
          animation-duration: 3s;
          @keyframes h1Animation {
            from {
              transform: translateY(-100px);
              opacity: 0;
              visibility: hidden;
            }
            to {
              transform: translateY(0px);
              opacity: 1 !important;
              visibility: visible;
            }
          }
        }
        >h1,>h2,>img{
          transform: translateX(0px);
          animation-name: pAnimation;
          transition: transform 150s;
          animation-duration: 3s;
          @keyframes pAnimation {
            from {
              transform: translatex(-100%);
              opacity: 0;
              visibility: hidden;
            }
            to {
              transform: translateX(0px);
              opacity: 1 !important;
              visibility: visible;
            }
          }
        }
      }
    }
    >.section{
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      >img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        @media (max-width:768px) {
          width: fit-content;
          position: absolute;
          left: -600px;
        }
      }
      >div{
        .description{
          position: absolute;
          top: 30%;
          left:50%;
          z-index: 101;
          @media (max-width:1100px) {
            left: 40%;
          }
          @media (max-width:910px) {
            left: 30%;
          }
          @media (max-width: 500px) {
            left: 20%;
          }
          @media (max-height:700px) {
            top: 20%;
          }
          @media (max-height:600px) {
            top: 10%;
          }
          
          >p{
            font-size: 120px;
            line-height: 120px;
            color: rgb(246, 32, 44);
            font-family: Quentin;
            letter-spacing: -5px;
            margin-bottom: 0;
          }
          >h2,h1{
            font-size: 120px;
            line-height: 100px;
            color: white;
            font-family: "Oswald";
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 0;
            margin-left: -6px;      
            margin-bottom: 50px;
            @media (max-width: 768px) {
            margin-bottom: 30px;
              
            }
          }          
          >a{
            white-space: nowrap;
            font-size: 30px;
            line-height: 30px;
            color: rgb(255, 255, 255);
            font-family: "Roboto";
            font-weight: 600;
            text-transform: uppercase;
            background-color: rgb(246, 32, 44);
            border-radius: 5px;
            padding: 14px 50px 15px;
            border-color: transparent;
            cursor: pointer;
            margin-top: 300px;
            @media (max-width:768px) {
              font-size: 24px;
            }
            @media (max-width:500px) {
              font-size: 20px;
            }
          }
          @media (max-width:768px) {
            >p{
              font-size: 65px;
              line-height: 60px;
              font-weight: 400;
            }
            >h1,>h2{
              font-size: 62px;
              line-height: 55px;
            }
          }
        }
        >.restaurant{
          @media (max-width: 460px) {
            left: 10%;
          }
          >p{
            font-size: 74px;
            line-height: 111px;
            font-weight: 100;
          }
          >img{
            width: 368px;
            margin-bottom: 40px;
          }
    
          @media (max-width: 768px) {
            >p{
              font-size: 50px;
            }
            >img{
              width: 290px;
            }
          }    
        }
        >.titlePart2{
          >p{
            color:  rgb(1, 73, 131);
            font-weight: 400;
            font-size: 100px;
          }
          >h2{
            line-height: 140px;
          }
          >a >button{
            background-color: rgb(1, 73, 131);;
          }
          @media (max-width:768px) {
            >p{
              font-size: 80px;
            }
          }
        }
        >.anniversaire{
          left: 16%;
          @media (max-width:915px) {
            /* text-align: center; */
            left: 50px;
          }
          @media (max-width:820px) {
            left: 30px
          }
          @media (max-width:768px) {          
            left: 30%;
            >p{
              font-size: 65px;
              line-height: 60px;
              font-weight: 400; 
            }
            >h2{
              font-size: 45px;
              line-height: 70px;
            }
          }
          @media (max-width:768px) {          
            left: 20%;
          }
          @media (max-width:768px) {          
            left: 10%;
          }
        }
        >.laser{
          p{
            font-size: 120px;
            font-weight: 200;
            color:  rgb(1, 73, 131);
          }
          >h2{
            line-height: 110px;
          }
          >a >button{
            background-color: rgb(1, 73, 131);;
          }
          @media (max-width: 768px) {
            >p{
              font-size: 70px;
              line-height: 60px;
              font-weight: 400;
            }
            >h2{
              font-size: 80px;
              line-height: 70px;
              font-weight: 500;
            }
          }          
        }
        >.quiz{
          left: 16%;
          text-align: left;
        }
      }
    }
    >.sectionAgenda{    
      display: flex;
      justify-content: center;  
      >div {
        display: flex !important;
        justify-content: center;
        align-items: center;
        >.agenda{
          /* position: absolute; */
          z-index: 101;
          top: 20%;
          
          @media (max-height:720px) {
            top: 15%;
          }
          >div{
            @media (max-width: 1150px) {
              padding: 0;
            }
            >.card {
              background-color: rgba(255, 255, 255, 0.9);
              padding: 37px 0px 0 30px;
              margin-left: 20px;
              margin-right: 20px;
              height: 100%;
              min-width: 298px;
              min-height: 503px;
              @media (max-width:1150px) {
                margin: 0 10px;
                padding: 25px 25px 0;
                min-height: 422px;
                min-width: 255px;
              } 
              @media (max-width:410px) {
                /* min-height: auto; */
                min-height: 322px;
                min-width: auto;
              }                
              >.title ,>form >.title{
                font-size: 20px;
                line-height: 22px;
                color: rgb(48, 48, 48);
                font-family: "Oswald";
                font-weight: 500;
                margin-top: 0;
                @media (max-width:1150px) {
                  font-size: 16px;
                }
              }
              >.mainTitle{
                font-size: 20px;
                padding-bottom: 25px;
                @media (max-width:1150px) {
                  font-size: 16px;
                }
                @media (max-height:680px) {
                  padding-bottom: 30px;
                }
              }
              >ul{
                list-style-type: none;
                padding: 0;
                >li{

                  color: rgb(103, 99, 99);
                  font-family: "Oswald",sans-serif;
                  margin-bottom: 26px;
                  font-size: 15px;
                  @media (max-width:1150px) {
                    margin-bottom: 15px;
                    font-size: 12px;
                  }
                  >span{
                    font-weight: 500;
                    color: rgb(30, 26, 26);
                    font-size: 14px;
                    @media (max-width:1150px) {
                      font-size: 11px;
                    }
                  }
                }
              }
              >a >.pContacter{
                margin: 0;
                font-family: Roboto;
                line-height: 22px;
                color: black;     
                font-size:13px;
                @media (max-width:1150px) {
                  padding-bottom: 0px;
                  font-size:11px;
                }
              }
              >a >.tel{
                padding-bottom: 24px;
                @media (max-width:1150px) {
                  padding-bottom: 0px;
                }

              }
              >a >.social-icon{
                background-color: black;
                color: white;
                padding: 4px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
              }
              >form{
                padding-top: 18px;
                >.groupe{
                  position: relative;
                  >.inputContacter{
                  background-color: transparent;
                  font-size: 14px;
                  margin: 0;
                  padding: 5px 14px;
                  border-radius: 3px;
                  border:1px solid #e1e1e1;
                  }
                  >label{
                    color: red;
                    position: absolute;
                    top: 87px;
                    left: 0px;
                    font-size: 12px;
                    font-weight: bold;
                  }
                  >.success{
                    color: #408140;
                  }
                }
                >button{
                  background-color: red;
                  padding: 4px 18px;
                  font-family: "Oswald",sans-serif;
                  border: 2px solid transparent;
                  border-radius: 3px;
                  margin-top: 11px;
                  letter-spacing: 0.15em;
                  text-transform: uppercase;
                  color: white;
                  width: fit-content;
                  font-weight: 900;
                }  
              }                            
            }
          }
          @media (max-width:920px) {
            >.hide{
              display: none;
            }
            >div{
              width: auto;
              @media (max-width: 500px) {
                width: 260px;
              }
              @media (max-width: 420px) {
                width: 254px;
              }
            }
          }
        }
      }
    }  
  }
  >#pagepiling #pp-nav li a span{
    color: white;
    background-color: white;
    border-color: white;
  }
`

export default Main