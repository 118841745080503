import React from "react";
import styled from "styled-components"
import { Helmet, HelmetProvider } from "react-helmet-async";

const Billard = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Billard - Space Bowling</title>
          <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
        </Helmet>
        <Container className="container-fluid">
          <Body>
            <div className="title">
              <p className="titlePart1">tables de</p>
              <h1 className="titlePart2">Billard</h1>
            </div>
            <div className="contents">
              <div className="part1">
                <h2>LES AVANTAGES DE JOUER AU BILLARD</h2>
                <p>Le billard fait partie des jeux de distraction préférés de nombreuses personnes. Il présente divers avantages comme le développement de notre capacité de concentration. Idéal pour tous, le billard nous apprend entre autres à avoir un raisonnement logique. Alors, pourquoi ne pas prendre du bon temps tout en faisant des exercices intellectuels, votre précision sera également mise à l’épreuve. Pour ceux qui se trouvent à proximité, le complexe SPACE BOWLING vous ouvre les portes de sa salle de billard.</p>
              </div>
              <div className="empty_space"></div>
              <div className="part2">
                <h2>DES ÉQUIPEMENTS DE BONNE QUALITÉ</h2>
                <p>Avez-vous envie de vous changer les idées ou de profiter de votre temps libre avec vos proches ? Outre le bowling et le laser, le complexe SPACE BOWLING vous propose également le billard. Rassurez-vous, nos tables, nos boules et nos queues de billard sont tous en très bons états et de bonne qualité. Outre les jeux, nous vous laissons profiter de nos nombreuses animations.</p>
              </div>
            </div>
          </Body>
        </Container>
        <Images>
          <div className="row">
            <div className="col-6">
              <img className="blog1" src="/images/billard/billard1.png" alt="billard1" />
            </div>
            <div className="col-6 row">
              <div className="col-6">
                <img className="blog2" src="/images/billard/billard2.png" alt="billard2" />
              </div>
              <div className="col-6">
                <img className="blog3" src="/images/billard/billard3.png" alt="billard3" />
                <img className="blog3" src="/images/billard/billard4.png" alt="billard4" />
              </div>
            </div>
          </div>
        </Images>
      </div>
    </HelmetProvider>
  )
}
const Container = styled.div`
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
`;

const Body = styled.div`
  padding-top:80px;
  padding-bottom:70px;
  font-family: Montserrat;
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: right;
    padding-bottom:80px;
    >.titlePart1{
      font-family: Quentin, script;
      color: #1d4684;
      font-size: 60px;
      position:relative;
      top: 12px;
      font-weight: 600;
      margin:0;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
    >.titlePart2{
      font-size:70px;
      font-weight:900;
      margin:0;
      font-family: "Oswald", sans-serif;
      letter-spacing: .02em;
      text-transform: uppercase;
      color: #141414;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.contents{
    >div {
      >h2{
      color: #1e4586;
      margin:0;
      font-size: 25px;
      }
      >p{
        margin-bottom:0;
      }
    }
  }
  @media (max-width:768px) {
    padding-top:30px;
    >.title{
      padding-bottom:50px;
    }
  }
`

const Images = styled.div`
  >.row{
    >div{
      padding: 0;
      margin: 0;
      >div{
        padding: 0;
      }
      >.blog1, >div .blog2{
        width: 100%;
        height: 100%;
      }
      >div .blog2{
        padding: 0 1px;
      }
      >div .blog3{
        width: 100%;
        height: 50%;
        &:first-child{
          padding-bottom: 1px;
        }
      }   
    }
  }
`


export default Billard;