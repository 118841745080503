import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Helmet, HelmetProvider } from "react-helmet-async";

const ContactezNous = () => {

  const inputRefName = useRef("");
  const inputRefEmail = useRef("");
  const inputRefMessage = useRef("");
  let error = useRef({})
  let [errorForm, setErrorFrom] = useState({})
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const [success, setSuccess] = useState("");

  const handleSubmit = e => {
    e.preventDefault()
    // get the ref value
    // clear the input

    inputRefName.current.value === "" ? (error.current.name = "Nom est Obligatoire") : (delete error.current.name)
    inputRefEmail.current.value === "" ? (error.current.email = "Email est Obligatoire") : !validEmail.test(inputRefEmail.current.value) ? (error.current.email = "Email est incorect") : (delete error.current.email)
    inputRefMessage.current.value === "" ? (error.current.message = "Message est Obligatoire") : (delete error.current.message)

    if (!Object.keys(error.current).length) {
      inputRefName.current.value = "";
      inputRefEmail.current.value = "";
      inputRefMessage.current.value = "";
      setSuccess("Inscription réussie")
    } else setSuccess("")

    setErrorFrom(errorForm => ({ ...error.current }))
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contactez - Space Bowling</title>
        <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
      </Helmet>
      <Container>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <div className="title">
          <p className="titlePart1">Nous</p>
          <h1 className="titlePart2">Contactez</h1>
        </div>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=space%20bowling&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="spaceBowling" className="map" ></iframe>
        <div className="empty_space"></div>

        <div className="contact">
          <a href="mailto:contact@spacebowling.fr"><FontAwesomeIcon icon={faEnvelope} className="icon" /> contact@spacebowling.fr</a>
          <a href="https://www.google.rs/maps/place/130+Rue+Michel+Debr%C3%A9,+30000+N%C3%AEmes,+France/@43.8150403,4.3653194,17z/data=!3m1!4b1!4m5!3m4!1s0x12b42ce4d8087705:0x3b94be3c06f1beff!8m2!3d43.8150365!4d4.3675134" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLocationDot} className="icon" /> 130 Rue Michel Debré, 30000 Nîmes</a>
          <a href="tel:04 66 580 580"><FontAwesomeIcon icon={faPhone} className="icon" /> 04 66 580 580</a>
        </div>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <Body>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="title">
            <p className="titlePart1">Nous</p>
            <h2 className="titlePart2">Écrire</h2>
          </div>
          <div>
            <div className="empty_space"></div>
            <form method="post" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <input placeholder="Nom" ref={inputRefName} />
                  <div className="empty_space show"></div>
                  <label>{errorForm !== undefined && errorForm.name}</label>
                </div>
                <div className="col-sm-6 col-12">
                  <input placeholder="Email" ref={inputRefEmail} />
                  <label>{errorForm !== undefined && errorForm.email}</label>
                </div>
                <div className="empty_space"></div>
                <div className="col-12">
                  <input placeholder="Message" ref={inputRefMessage} />
                  <label>{errorForm !== undefined && errorForm.message}</label>
                </div>
              </div>

              <div className="empty_space"></div>
              <div style={{ textAlign: "right" }}>
                {success !== "" && <label className="success">{success}</label>}
                <button className="submit">Envoyer</button>
              </div>
            </form>
            <div className="empty_space"></div>
            <div className="empty_space"></div>
          </div>
        </Body>
      </Container>
    </HelmetProvider>
  )
}

const Container = styled.div`
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: center;
    position: relative;
    >.titlePart1{
      font-family: Quentin,script;
      color: #e23726;
      font-size: 60px;
      top: 12px;
      font-weight: 500;
      margin:0;
      margin-left: 231px;
      @media (max-width:768px) {
        font-size: 45px;
        margin-left: 125px;
      }
    }
    >.titlePart2{
      font-size:70px;
      font-weight:900;
      margin:0;
      font-family: "Oswald",sans-serif;
      letter-spacing: .02em;
      text-transform: uppercase;
      color: #141414;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.map{
    padding: 0 15%;
    width: 100%;
    height: 450px;
    @media (max-width: 768px) {
      padding: 0 10%;
    }
    @media (max-width: 460px) {
      padding: 0;
    }
  }
  >div{
    text-align:center;
    padding: 0 15%;
    @media (max-width: 768px) {
      padding: 0 10%;
    }
    @media (max-width: 460px) {
      padding: 5%;
    }
  }
  >.contact{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    white-space: nowrap;
    @media (max-width:1330px) {
      flex-direction: column;
    }
    >a{
      color: black;
      display: flex;
      align-items: center;
      color: black;
      font-size: 18px;
      font-family: Montserrat;
      font-weight: 500;
      margin-top: 25px;
      @media (max-width:440px) {
        font-size: 15px;
      }
      >.icon{
        color: #e23726;
        border: 1px solid black;
        width: 16px;
        border-radius: 50%;
        padding: 10px;
        margin-right: 20px;
      }
    }
  }
`;
const Body = styled.div`
  padding: 0 10%;
  @media (min-width: 768px) {
    padding: 0 15%;
  }
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: center;
    position: relative;
    >.titlePart1{
      font-family: Quentin,script;
      color: #e23726;
      font-size: 60px;
      top: 12px;
      font-weight: 500;
      margin:0;
      margin-left: 84px;
      @media (max-width:768px) {
        font-size: 45px;
        margin-left: 42px;
      }
    }
    >.titlePart2{
      font-size:70px;
      font-weight:900;
      margin:0;
      color: white;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  .icons{
    background-color: white;
    padding-right: 10px;
    height: 30px;
  }

  background-color: #000;
  >div {
    margin-top: 20px;
    font-family: "Oswald";
    font-weight: 500;
    letter-spacing: .02em;
    color: #000;
    >.title{
      color: white;
      letter-spacing: .02em;
      text-transform: uppercase;
      font-size: 60px;
      line-height: 33px;
      margin-bottom: 20px;
      padding-bottom: 10px;

    }
    >form >div {
      position: relative;
      >input, >div >input{
      width: 100%;
      margin: 0 0 20px;
      padding: 14px 18px;
      border-radius: 3px;
      font-size: 16px;
      font-family: sans-serif;
      background-color: transparent;
      color: white;
      border: 0;
      border-bottom: 2px solid white;
      &::placeholder{
        color: white;
        margin-left: -20px;
      }
    }
    >div{
      position: relative;
      >label{
      color: red;
      position: absolute;
      top: 65px;
      left: 10px;
      }
    }
    >.success{
      color: #408140;
      margin-right: 30px;
      @media (max-width:500px) {
        position: absolute;
        top: 62px;
        right: 24px;
      }
    }
    >.submit{
      background-color: #f6202c;
      line-height: 20px;
      font-weight: 900;
      font-size: 16px;
      letter-spacing: .15em;
      text-transform: uppercase;
      width: 220px;
      height: 50px;
      color: #fff;
      border: 2px solid transparent;
      border-radius: 3px;
      &:hover{
        background-color: #000;
      }
    }
    >div >.empty_space.show{
      display: none;
      @media (max-width: 575px) {
        display: block;
      }
    }
  }
}`

export default ContactezNous