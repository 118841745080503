import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faAt, } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import Horaires from "../model/horaire"

const Footer = () => {

  // const [horairesHor, setHorairesHor] = useState();
  // const [horaires, setHoraires] = useState();

  // useEffect(() => {

  //   fetch("http://dev.nimes.sofis-info.com/api/web/schedule")
  //     .then(response => response.json())
  //     .then(result => {
  //       setHorairesHor(result.data.schedule.CLASSIQUES);
  //       setHoraires(result.data.schedule['PETITES VACANCES SCOLAIRES']);
  //       console.log((result.data.schedule['PETITES VACANCES SCOLAIRES']))
  //     })
  //     .catch(error => console.log(error));

  // }, [])

  // let hor = []
  // if (horairesHor !== undefined) {
  //   for (const [key, val] of Object.entries(horairesHor)) {
  //     hor.push(new Horaires(key, val))
  //   }
  // }

  // let scolaires = []
  // if (horairesHor !== undefined) {
  //   for (const [key, val] of Object.entries(horaires)) {
  //     scolaires.push(new Horaires(key, val))
  //   }
  // }

  return (
    <div>
      <Footerdiv className="d-flex justify-content-center">
        <div className="row">
          <div className="col-12 col-sm-6 col-xl-3">
            <p className="title-footer">Hors vacances scolaires</p>
            <table id="horaire">
              <tbody>
              <tr>
                  <td>LUNDI </td>
                  <td className="secondTd">.............</td>
                  <td>17H - 01H</td>
                </tr>
                <tr>
                  <td>MARDI</td>
                  <td className="secondTd">.............</td>
                  <td>17H - 01H</td>
                </tr>
                <tr>
                  <td>MERCREDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr><td>JEUDI</td>
                  <td className="secondTd">.............</td>
                  <td>17H - 01H</td>
                </tr>
                <tr>
                  <td>VENDREDI</td>
                  <td className="secondTd">.............</td>
                  <td>17H - 01H</td>
                </tr>
                <tr>
                  <td>SAMEDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr>
                  <td>DIMANCHE</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>

                {/* {hor !== undefined && hor.map((element,key) =>
                  <tr key={key}>
                    <td>{element.day.toUpperCase()}</td>
                    <td className="secondTd">.............</td>
                    <td>{element.time.toUpperCase()}</td>
                  </tr>
                )} */}
              </tbody>
            </table>
          </div>
          <div className="col-12 col-sm-6 col-xl-3">
            <p className="title-footer">Vacances scolaires</p>
            <table>
              <tbody>
                {/* {scolaires !== undefined && scolaires.map((element,key) =>
                  <tr key={key}>
                    <td>{element.day.toUpperCase()}</td>
                    <td className="secondTd">.............</td>
                    <td>{element.time.toUpperCase()}</td>
                  </tr>
                )}*/}
                <tr>
                  <td>LUNDI </td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr>
                  <td>MARDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td></tr>
                <tr>
                  <td>MERCREDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr>
                  <td>JEUDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr>
                  <td>VENDREDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr>
                  <td>SAMEDI</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>
                <tr>
                  <td>DIMANCHE</td>
                  <td className="secondTd">.............</td>
                  <td>13H - 01H</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div className="col-12 col-sm-6 col-xl-3">
            <p className="title-footer">Contactez-nous</p>
            <table className="contact-table">
              <tbody><tr>
                <td><FontAwesomeIcon icon={faLocationDot} className="icons" /></td>
                <td><a href="https://www.google.rs/maps/place/130+Rue+Michel+Debr%C3%A9,+30000+N%C3%AEmes,+France/@43.8150403,4.3653194,17z/data=!3m1!4b1!4m5!3m4!1s0x12b42ce4d8087705:0x3b94be3c06f1beff!8m2!3d43.8150365!4d4.3675134" target="_blank" rel="noopener noreferrer">130 Rue Michel Debré, 30000 Nîmes</a></td>
              </tr>
                <tr>
                  <td><FontAwesomeIcon icon={faPhone} /></td>
                  <td><a href="tel:04 66 580 580">04 66 580 580</a></td>
                </tr>
                <tr>
                  <td><FontAwesomeIcon icon={faAt} /></td>
                  <td><a href="mailto:contact@spacebowling.fr">contact@spacebowling.fr</a></td>
                </tr>
              </tbody></table>
          </div>
          <div className="col-12 col-sm-6 col-xl-3">
            <p className="title-footer">Informations</p>
            <div>
              <a href="/space-bowling">Qui sommes-nous ?</a><br />
              {/* <a href="/">Notre équipe</a><br /> */}
              {/* <a href="/">Plaisir et détente avec le bowling !</a><br /> */}
              <a href="/societes-groupes">Sociétés &amp; Groupes</a><br />
              <a href="/conditions"> Conditions</a><br />
              <a href="/contactez-nous">Nous contacter</a><br /></div>
          </div>
        </div>
      </Footerdiv>
      <CopyRight>
        <div className="row">
          <div className="col-md-4 col-12">
            <p className="title-footer">BOWLING NÎMES ©</p>
          </div>
          <div className="col-md-4 col-12 secondCol">
            <a className="social-icon-holder"
              href="/" target="_self">
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a>
            <a className="social-icon-holder"
              href="https://www.facebook.com/Space-Bowling-167510117974/" target="_self">
              <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
            </a>
          </div>
          <div className="col-md-4 col-12">
            <p className="date-footer">{new Date().getFullYear()}.</p>
          </div>
        </div>
      </CopyRight>
    </div>
  )
}

const Footerdiv = styled.div`
  width: 100%;
  vertical-align: middle;
  margin: 0;
  padding: 32px 0 72px;
  z-index: 110;
  background-color: #121212;
  color: white;
  @media (max-width: 1200px) {
    padding: 32px 15%;
  }
  @media (max-width: 750px) {
    padding: 32px 10%;
  }
  @media (max-width: 575px) {
    padding: 0px 15% 30px;
    text-align: -webkit-center;
  }
  @media (max-width: 490px) {
    padding: 0px 10% 30px;
  }
  @media (max-width: 410px) {
    padding: 0px 5% 30px;
  }
  
  >div{
    width: 1100px;
    list-style: none;
    @media (max-width:950px) {
      width: 950px;
    }
    >p{
      margin: 0 0 35px
    }
    >div{
      >.title-footer{
        font-family: "Oswald",sans-serif;
        margin: 0 0 35px;
        font-weight: 500;
        letter-spacing: .02em;
        font-size: 19px;
        padding-top: 40px;
      }
      >table{
        font-family: Montserrat;
      }
      >table >tbody{
        vertical-align : top ;
        >tr > .secondTd {
          padding: 0 15px;
        }
      }
      >.contact-table >tbody >tr {
        >.secondTd{
          padding: 0 20px;
        }
        >td{
          >i{
            padding: 5px 10px;
            }
          >a{
            margin-top:0;
            padding: 0 10px 5px;
            margin-bottom: 0;
            color: white;
            box-decoration-break: clone;
          }
        }
      }
      >div >a{
        text-decoration: none;
        color: white;
      }
    }
    
  }
`;

const CopyRight = styled.div`
  background-color: #000;
  color: white;
  padding: 16px 5%;
  display: flex;
  justify-content: center;
  text-align: center;
  >.row{
    width: 1323px;
    @media (max-width: 767px) {
      >.secondCol{
        padding: 7px 0;
      }
    }
  }


  >.row >div{
    >p{
      margin-bottom: 0;
    }
    .title-footer{
    font-family: "Oswald",sans-serif;
    font-size: 25px;
    line-height: 33px;
    font-weight: 500;
    letter-spacing: .02em;
    text-transform: uppercase;
    margin-top: 0;
    }
    >.social-icon-holder{
      font-size: 16px;
      margin: 10px;
      >.social-icon{
        position: relative;
        padding:7px;
        color: #000;
        background-color: #fff;
        border-radius: 50%;
        height: 15px;
        width: 15px;
      }
    }
    >.date-footer{
    font-family: "Oswald",sans-serif;
    font-weight: 500;
    letter-spacing: .02em;
    text-transform: inherit;
    font-size: 19px;
    line-height: 21px;
    margin-top: 0;
    }
  }  
`;

export default Footer