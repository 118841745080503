import React from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Helmet, HelmetProvider } from "react-helmet-async";

const SocietesGroupes = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Sociétés & Groupes - Space Bowling</title>
        <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
      </Helmet>
      <Body>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <h1 className="title">SOCIÉTÉS & GROUPES.</h1>
        <ul>
          <div className="empty_space"></div>
          <li>
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
            <p>Le complexe SPACE BOWLING met à disposition des prestations pour les Sociétés et Groupes.</p>
          </li>
          <li>
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
            <p>Nous sommes spécialisés en organisation d’événements : séminaires, soirées de fin d’année, arbres de noël…</p>
          </li>
          <li>
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
            <p>Pour tous renseignement, demandez dès maintenant notre nouvelle brochure pour la saison 2019-2020 en envoyant un mail à contact@spacebowling.fr ou en cliquant sur «contactez-nous»</p>
          </li>
        </ul>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
      </Body>
    </HelmetProvider>
  )
}


const Body = styled.div`
  padding: 0 10%;
  @media (min-width: 768px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  >.row {
    margin-right: 15px;
    margin-left: 15px;
  }

  >.title{
    font-family: "Oswald";
    color: #014983;
    letter-spacing: .02em;
    font-size: 25px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 0;
  }

  >ul {
    color: #000;
    font-family: Montserrat;
    font-size:15px;
    list-style-type: none;
    padding-left: 0;
    >li{
      position: relative;
      padding-left: 30px;
      >p{
        color: #0a0a0a;
        font-weight: 400;
        font-family: Montserrat;
        line-height: 26px;
        margin: 20px 0;
      }
      >.icon{
        position: absolute;
        left: 0;
        top: 5px;
        color: #014983;
        /* padding-right: 20px; */
      }
    }
  }
`

export default SocietesGroupes