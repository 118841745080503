import React from "react";
import styled from "styled-components"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCalendarDays, faCircleDown } from '@fortawesome/free-regular-svg-icons'
import { Helmet, HelmetProvider } from "react-helmet-async";

const QuizBoxing = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Quiz Boxing - Space Bowling</title>
          <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
        </Helmet>
        <Container className="container-fluid" >
          <Body>
            <div className="title">
              <p className="titlePart1">Quiz</p>
              <h1 className="titlePart2">Boxing</h1>
            </div>

            <div className="contents">
              <h2>
                Nouvelle activité
              </h2>
              <p>
                Unique en dans le Gard, et c’est au Spacebowling de Nîmes que ça se passe.
              </p>
              <p>
                On vous promet une immersion totale, dans un décor de dingue, digne d’un véritable plateau TV. Imaginez un show de sons et lumières, une arène de combat, 6 pupitres avec des buzzers pour répondre à des questions : c’est ça l’ambiance du Quiz Boxing.
              </p>
              <p>
                De 4 à 12 joueurs, vous allez affronter vos amis, votre famille ou vos collègues dans une série de questions survoltées. Au programme : cinéma, séries TV, dessins animés, culture et société, jeux-vidéos, sport, et même des blind tests musicaux. Vous ne connaissez pas les réponses ? Pas de panique, profitez des jokers pour attaquer, parier ou voler les points de vos adversaires, car tous les coups sont permis pour gagner ! C’est le 1er quiz où tout le monde peut gagner et où vous rigolerez 100 fois plus que dans votre canap’.
              </p>
              <h2>Informations générales sur l'activité :</h2>
              <ul>
                <li><span>Type de jeu :</span> jeu TV grandeur nature</li>
                <li><span>Age :</span> L’aventure est conseillée pour un public adulte ou familial à partir de 12 ans</li>
                <li><span>Durée :</span> 1h </li>
                <li><span>Nombre de joueurs :</span> de 4 à 12 joueurs</li>
                <li><span>La salle de quiz game est entièrement privatisée pour votre groupe</span></li>
              </ul>
            </div>
          </Body>
        </Container>
        <Images>
          <div className="row">
            <div className="col-6">
              <img className="blog1" src="/images/quizBoxing/QUIZ1.jpg" alt="QUIZ" />
            </div>
            <div className="col-6">
              <img className="blog1" src="/images/quizBoxing/QUIZ2.jpg" alt="QUIZ" />
            </div>
          </div>
        </Images>
      </div>
    </HelmetProvider>

  )
}

const Container = styled.div`
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
`;

const Body = styled.div`
  padding-top:80px;
  padding-bottom:70px;
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: right;
    padding-bottom:80px;
    >.titlePart1{
      font-family: Quentin,script;
      color: #1D4684;
      font-size: 60px;
      position:relative;
      top: 12px;
      font-weight: 600;
      margin:0;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
    >.titlePart2{
      color: #F6202C;
      font-size:70px;
      font-weight:900;
      margin:0;      
      font-family: "Oswald",sans-serif;
      letter-spacing: .02em;
      text-transform: uppercase;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.contents{
    >h2{
      color: #014983;
      font-size: 25px;
    }
    >.button{
      color: #ffffff;
      background-color: #f6202c;
      border-color: #f6202c;
      border: 2px solid transparent;
      border-radius: 3px;
    }
    >a >.icons{
      position: relative;
      top: -2px;
      right:-10px;
      vertical-align: middle;
      font-size: 1.3em;
    }
    >ul {
      padding-left: 23px;
      >li {
      list-style-type: none;
      padding-bottom: 2px;
      >span{
        font-weight: 600;
      }
      }
      >li::before{
        content: url("images/quizBoxing/Ellipse 17.svg");
        padding-right:9px;
      }
    }
  }
  @media (max-width:768px) {
    padding-top:30px;
    >.title{
      padding-bottom:50px;
    }
  }
  
`

const Images = styled.div`
  >.row {
    margin: 0;
    padding: 0;
    justify-content: space-between;
    >div{
      padding: 0;
      >div{
        padding: 0 1px;
      }
      >.blog1, >div .blog2{
        width: 100%;
        height: 100%;
      }

      >div .blog3{
        width: 100%;
        height: 50%;
        &:first-child{
          padding-bottom: 1px;
        }
    }   
  }
  }
`


export default QuizBoxing;