import React from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'
import { Helmet, HelmetProvider } from "react-helmet-async";

const Conditions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Conditions - Space Bowling</title>
        <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
      </Helmet>
      <Body>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <h1 className="title">CONDITIONS.</h1>
        <ul>
          <div className="empty_space"></div>
          <h2>Conditions Spécifiques Pour Les Réservations De Formules Anniversaires :</h2>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Acompte de 50€, et solde le jour de l’anniversaire, par CB, espèce ou ANCV</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Pas de remboursement de l’acompte en cas d’annulation. Nous pourrons réorganiser avec vous le décalage sur une autre date en fonction des disponibilités restantes, à condition que votre demande soit formulée au moins 48h à l’avance.</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Pas de changement de formule possible une fois la réservation effectuée</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Le nombre d’enfants indiqué au moment de la réservation est une « estimation ». Nous tiendrons compte du nombre total de personnes présentes le jour de l’événement. (Attention au nombre minimum et au maximum par formule) Le jour J, aucune personne au delà du nombre maximum ne pourra être accepté</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Pour toute réservation avec plus de 12 personnes, merci de nous contacter directement par mail afin que nous puissions étudier votre demande au préalable</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Prévoir d’arriver 30mn avant l’heure de votre réservation</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Présence d’un parent responsable obligatoire le jour de l’événement</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Nourriture et boissons extérieures interdites</li>
          <li><FontAwesomeIcon icon={faCircleRight} className="icon" />Le port de chaussettes est obligatoire avec des chaussures de bowling</li>
        </ul>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
      </Body>
    </HelmetProvider>

  )
}


const Body = styled.div`
  padding: 0 10%;
  @media (min-width: 768px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  >.row {
    margin-right: 15px;
    margin-left: 15px;
  }
  >.title{
    font-family: "Oswald";
    color: #014983;
    letter-spacing: .02em;
    font-size: 25px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 0;
  }

  >ul {
    color: #000;
    font-family: Montserrat;
    font-size:15px;
    list-style-type: none;
    padding-left: 0;
    >h2{
      font-family: "Oswald",sans-serif;
      font-weight: 500;
      letter-spacing: .02em;
      font-size: 19px;
      line-height: 21px;
      text-transform: none;
    }
    >li{
      position: relative;
      padding-left: 30px;
      color: #0a0a0a;
      font-weight: 400;
      font-family: Montserrat;
      >.icon{
        position: absolute;
        left: 0;
        top: 5px;
        color: #014983;
        width: 11px;
        /* padding-right: 20px; */
      }
    }
  }
`

export default Conditions