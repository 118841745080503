import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'


const NavBar = () => {

  const [showMenu, setShowMenu] = useState(true);
  const [classNameNavBarTablette, setClassNameNavBarTablette] = useState("nav-tablette");

  function openMenu() {
    setClassNameNavBarTablette("nav-tablette show");
    setShowMenu(false);
    document.body.classList.add("stop-scrolling");
  }

  function closeMenu() {
    setClassNameNavBarTablette("nav-tablette");
    setShowMenu(true)
    document.body.classList.remove("stop-scrolling");
  }

  return (
    <Header>
      <a href="/"><img className="logo" src="/images/logo.png" alt="logo" /></a>
      <div className="navbar d-flex  justify-content-center">
        <ul className="menu-laptop d-flex justify-content-between align-items-center">
          <li><a href="/space-bowling" className="item_text">SPACE BOWLING</a></li>
          <li><a href="/quiz-boxing"  className="item_text">QUIZ BOXING</a></li>
          <li><a href="/billard" className="item_text">BILLARD</a></li>
          <li><a href="/anniversaire" className="item_text">ANNIVERSAIRE</a></li>
          <li><a href="/laser-game" className="item_text">LASER GAME</a></li>
          <li><a href="/nos-tarifs" className="item_text">NOS TARIFS</a></li>
          <li><a href="/contactez-nous" className="item_text">CONTACTEZ-NOUS</a></li>
        </ul>
      </div>
      <a href="https://reservation.spacebowling.fr/" target="_blank" rel="noopener noreferrer" className="reserve"><img src="./images/navbar/Path 14.svg" alt="" /> <p>je réserve ici</p></a>

      <div className={`${classNameNavBarTablette}`}>
        {showMenu && <FontAwesomeIcon icon={faBars} className="icon" onClick={() => openMenu()} />}
        {!showMenu && <FontAwesomeIcon icon={faXmark} className="icon iconX" onClick={() => closeMenu()} />}
        <ul className="menu-tablette" >
          <li style={{ animationDelay: "50ms" }}><a href="/space-bowling" className="item_text">SPACE BOWLING</a></li>
          <li style={{ animationDelay: "100ms" }}><a href="/quiz-boxing" className="item_text">QUIZ BOXING</a></li>
          <li style={{ animationDelay: "150ms" }}><a href="/billard" className="item_text">BILLARD</a></li>
          <li style={{ animationDelay: "200ms" }}><a href="/anniversaire" className="item_text">ANNIVERSAIRE</a></li>
          <li style={{ animationDelay: "250ms" }}><a href="/laser-game" className="item_text">LASER GAME</a></li>
          <li style={{ animationDelay: "300ms" }}><a href="/nos-tarifs" className="item_text">NOS TARIFS</a></li>
          <li style={{ animationDelay: "350ms" }}><a href="/contactez-nous" className="item_text">CONTACTEZ-NOUS</a></li>
          <li style={{ animationDelay: "400ms" }} className="reserve-visibility">
            <a href="https://reservation.spacebowling.fr/" target="_blank" rel="noopener noreferrer" className="reserve"><img src="./images/navbar/Path 14.svg" alt="" /> <p>je réserve ici</p></a>
          </li>
        </ul>

      </div>
    </Header>
  )
}

const Header = styled.div`
  width: 100%;
  height: 98px;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  z-index: 110;
  background-color: #000;
  color: white;
  >a >.logo{
    position: absolute;
    top: 0;
    left: 30px;
    height: 98px;
    z-index: 300;
    @media (max-width:768px) {
      top:6px;
      height: 75px;
    }
  }
  .reserve{
    color: white;
    position: absolute;
    top: 22px;
    right: 35px;
    font-size: 16px;
    font-weight: bold;
    font-family: "montserrat",sans-serif;
    text-transform: uppercase;
    display: flex;
    border: 3px solid #F6202C;
    padding: 0 12px;
    @media (min-width: 1201px) {
      position: absolute;
      top: 0;
      right: 45px;
      width: fit-content;
      float: right;
    }
    &:hover{
      >img{
        filter: invert(28%) sepia(62%) saturate(5185%) hue-rotate(342deg) brightness(93%) contrast(108%);
      }
      >p{
        color: #F6202C;
      }
    }
    @media (max-width:1200px) {
      right: 104px;
      border-radius: 6px;
    }
    @media (max-width:460px) {
      display: none;
    }
    >img{
      padding-right: 8px;
      filter: invert(96%) sepia(0%) saturate(4833%) hue-rotate(308deg) brightness(140%) contrast(100%);
      &:hover{
        filter: invert(28%) sepia(62%) saturate(5185%) hue-rotate(342deg) brightness(93%) contrast(108%);
      }
    }
    >p{
      margin: 8px 0;
      &:hover{
        color: #F6202C;
      }
    }
  } 
  
  >.navbar{
    height: 98px;
    height: 100%;
    padding: 0 30px;
    /* @media (max-width: 1360px) {
      padding: 0 100px 0 30px;
    } */
    /* @media (max-width:1280px) {
      padding: 0 246px 0 156px
    } */
    @media (max-width: 1200px) {
      display: none !important;
    }

    .menu-laptop{
      @media (max-width: 1200px) {
        display: none !important;
      }
    }
    >ul{
      width: 870px;
      height: 100%;
      list-style: none;
      padding-left: 0 !important;
      font-family: "Oswald",sans-serif;
      font-weight: 500;
      letter-spacing: .15em;
      @media (min-width: 1301px) {
        width: 960px;
      }
      @media (min-width: 1421px) {
        width: 1100px;
      }
      @media (min-width: 1201px) {
        padding-top: 33px;
      }

      >li{
        cursor: pointer;
        >.item_text{
          text-decoration:none;
          color: white;
          font-weight: 400 !important;
          &:hover{
            color: #f6202c;
          }
        }
      }
      >.item_text{
        text-transform: uppercase;
      }
    }
  }
  .nav-tablette{
    display: none;
    font-size: 30px;
    height: 98px;
    background-color: #000;
    @media (max-width:1200px) {
      display: block;
    }
    >.menu-tablette{
      display: none;
      height: 100%;
      list-style-type: none;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      >li{
        padding-bottom: 30px;
        transform: translateY(0px);
        animation-name: navbarAnimation;
        transition: transform 150s;
        animation-duration: 3s;
        @keyframes navbarAnimation {
          from {
            transform: translateY(50px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1 !important;
          }
        }
        >.item_text{
          text-decoration:none;
          color: white;
          font-weight: 400 !important;
          &:hover{
            color: #f6202c;
          }
        }
      }
      >.reserve-visibility{
        @media (min-width: 461px) {
          display: none;
        }
        @media (max-width: 460px) {
          display: block;
          >.reserve{
            display: flex !important;
            position: relative;
            top: auto;
            right: auto;
            width: fit-content;
            >p{
              font-size: 26px;
              font-weight: 400;
              padding: 6px;
            }
          }
        }
      }
    }
    >.icon{
      position: absolute;
      right: 50px;
      top: 30px;
      width: 26px;
      cursor: pointer;
      >path{
        width: 26px;
      }
    }
    >.iconX{
      top: 25px;
      font-size: 41px;
    }
  }
  .nav-tablette.show{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;    
    width: 100%;
    height: 100%;
    z-index: 110;
    >.menu-tablette{
      display: flex;
    }
  }    
`;

export default NavBar