import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
// import Horaires from './model/horaire.js'

const Agenda = () => {

  // const [horairesHor, setHorairesHor] = useState();
  // const [horaires, setHoraires] = useState();

  // useEffect(() => {

  //   fetch("http://dev.nimes.sofis-info.com/api/web/schedule")
  //     .then(response => response.json())
  //     .then(result => {
  //       setHorairesHor(result.data.schedule.CLASSIQUES);
  //       setHoraires(result.data.schedule['PETITES VACANCES SCOLAIRES']);
  //     })
  //     .catch(error => console.log(error));

  // }, [])

  // let hor = []
  // if (horairesHor !== undefined) {
  //   for (const [key, val] of Object.entries(horairesHor)) {
  //     hor.push(new Horaires(key, val))
  //   }
  // }

  // let scolaires = []
  // if (horairesHor !== undefined) {
  //   for (const [key, val] of Object.entries(horaires)) {
  //     scolaires.push(new Horaires(key, val))
  //   }
  // }
  return (
    <div className="row agenda">
      <div className="col-4 hide">
        <div className="card">
          <p className="title mainTitle">Horaire Hors vacances scolaires</p>
          <ul>
            {/* {hor !== undefined && hor.map((element, key) =>
              <li key={key}>
                <span>{element.day.toUpperCase()} &nbsp;</span>
                {element.time.toUpperCase()}
              </li>
            )} */}

            <li><span>LUNDI &nbsp;</span> 17:00 - 01:00</li>
            <li><span>MARDI &nbsp;</span> 17:00 - 01:00</li>
            <li><span>MERCREDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>JEUDI&nbsp;</span> 17:00 - 01:00</li>
            <li><span>VENDREDI&nbsp;</span> 17:00 - 01:00</li>
            <li><span>SAMEDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>DIMANCHE&nbsp;</span> 13:00 - 01:00</li>

          </ul>
        </div>
      </div>
      <div className="col-4 hide">
        <div className="card">
          <p className="title mainTitle">Horaire vacances scolaires</p>
          <ul>
            {/* {scolaires !== undefined && scolaires.map((element, key) =>
              <li key={key}>
                <span>{element.day.toUpperCase()} &nbsp;</span>
                {element.time.toUpperCase()}
              </li>
            )} */}
            <li><span>LUNDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>MARDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>MERCREDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>JEUDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>VENDREDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>SAMEDI&nbsp;</span> 13:00 - 01:00</li>
            <li><span>DIMANCHE&nbsp;</span> 13:00 - 01:00</li>

          </ul>
        </div>
      </div>
      <div className="col-4">
        <div className="card">
          <p className="title mainTitle">Nous contacter</p>
          <a href="https://www.google.rs/maps/place/130+Rue+Michel+Debr%C3%A9,+30000+N%C3%AEmes,+France/@43.8150403,4.3653194,17z/data=!3m1!4b1!4m5!3m4!1s0x12b42ce4d8087705:0x3b94be3c06f1beff!8m2!3d43.8150365!4d4.3675134"><p className="pContacter">130 Rue Michel Debré, 30000 Nîmes</p></a>
          <a href="mailto:contact@spacebowling.fr"><p className="pContacter">contact@spacebowling.fr</p></a>
          <a href="tel:04 66 580 580"><p className="pContacter tel">04 66 580 580</p></a>
          <br />
          <p className="title">Nous suivre</p>
          <a href="https://www.facebook.com/pages/Space-Bowling-Nimes/119438238124939">
            <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
          </a>
          {/* <form onSubmit={handleSubmit}>
                      <p className="title">Newsletters</p>
                      <div className="groupe">
                        <input className="inputContacter" placeholder="Entrez votre email" value={inputRefEmail} onChange={handleOnChange} />
                        {error !== undefined && <label>{error}</label>}
                        {success !== "" && <label className="success">{success}</label>}
                      </div>
                      <button type="submit">S'INSCRIRE</button>
                      <br />
                      <br />
                    </form> */}
        </div>
      </div>
    </div>
  )
}

export default Agenda

