import React from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCircleDown } from '@fortawesome/free-regular-svg-icons'
import { Helmet, HelmetProvider } from "react-helmet-async";

const Anniversaire = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Anniversaire - Space Bowling</title>
          <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
        </Helmet>
        <Container className="container-fluid" >
          <Body>
            <div className="title">
              <p className="titlePart1">Réservation</p>
              <h1 className="titlePart2">Anniversaire</h1>
            </div>

            <div className="contents">
              <p>
                Parce que son anniversaire doit être un moment original et unique, l’équipe du SPACE BOWLING sera heureuse d’organiser l’anniversaire de votre enfant pour un souvenir mémorable !
              </p>
              <p>
                Grace à notre nouveau système BES X, nous sommes prets à vous faire vivre une toute nouvelle expérience et passez un très bon moment.<br />
                Vos enfants pourront découvrir sur nos pistes les MAD GAMES qui leur feront vivre une expérience de jeux différente de celle du Bowling « classique » à 10 quilles.<br />
                Ils choisiront notre Usine à Monstres ou à Personnages, You Toons ou la Bataille sur les Pistes.<br />
                Ils vont les adorer !
              </p>
              <div className="empty_space"></div>
              <a href="https://reservation.spacebowling.fr/storage/files/1/Invitation_SpaceBowling.pdf" target="_blank" rel="noopener noreferrer" className="b-btn button">
                <span>Télécharger les invitations</span>
                <FontAwesomeIcon icon={faCircleDown} className="icons" />
              </a>
              <div className="empty_space"></div>
              <a href="https://reservation.spacebowling.fr" target="_self" className="b-btn button">
                <span>Réserver une fête</span>
                <FontAwesomeIcon icon={faCalendarDays} className="icons" />
              </a>
            </div>


          </Body>
        </Container>
        <Images>
          <div className="row">
            <div className="col-4">
              <img className="blog1" src="/images/anniversaire/box.jpg" alt="box" />
            </div>
            <div className="col-4 row">
              <div className="col-6">
                <img className="blog2" src="/images/anniversaire/Anniversaire2.png" alt="Anniversaire2" />
              </div>
              <div className="col-6">
                <img className="blog3" src="/images/anniversaire/Anniversaire4.png" alt="Anniversaire4" />
                <img className="blog3" src="/images/anniversaire/kids-spacebowling.jpg" alt="kids-spacebowling" />
              </div>
            </div>
            <div className="col-4">
              <img className="blog1" src="/images/anniversaire/Anniversaire1.png" alt="Anniversaire1" />
            </div>
          </div>
        </Images>
      </div>
    </HelmetProvider>

  )
}

const Container = styled.div`
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
`;

const Body = styled.div`
  padding-top:80px;
  padding-bottom:70px;
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: right;
    padding-bottom:80px;
    >.titlePart1{
      font-family: Quentin,script;
      color: #e23726;
      font-size: 60px;
      position:relative;
      top: 12px;
      font-weight: 600;
      margin:0;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
    >.titlePart2{
      font-size:70px;
      font-weight:900;
      margin:0;      
      font-family: "Oswald",sans-serif;
      letter-spacing: .02em;
      text-transform: uppercase;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.contents{
    >.button{
      color: #ffffff;
      background-color: #f6202c;
      border-color: #f6202c;
      border: 2px solid transparent;
      border-radius: 3px;
    }
    >a >.icons{
      position: relative;
      top: -2px;
      right:-10px;
      vertical-align: middle;
      font-size: 1.3em;
    }
  }
  @media (max-width:768px) {
    padding-top:30px;
    >.title{
      padding-bottom:50px;
    }
  }
  
`

const Images = styled.div`
  >.row {
    margin: 0;
    padding: 0;
    justify-content: space-between;
    >div{
      padding: 0;
      >div{
        padding: 0 1px;
      }
      >.blog1, >div .blog2{
        width: 100%;
        height: 100%;
      }

      >div .blog3{
        width: 100%;
        height: 50%;
        &:first-child{
          padding-bottom: 1px;
        }
    }   
  }
  }
`


export default Anniversaire;