import React from "react";
import styled from "styled-components"
import { Helmet, HelmetProvider } from "react-helmet-async";

const NotFound = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Page non touvée - Space Bowling</title>
      </Helmet>
      <Body>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <img src="/images/notFound/404-image.png" alt="404image" />
        <div className="empty_space"></div>
        <p className="whooops">Whooops</p>
        <h1 className="title">Something went Wrong!</h1>
        <div className="empty_space"></div>
        <p className="paragraph">The page you are looking for does not exist. It may have been moved, or removed altogether. Perhaps you can return back to the site's homepage and see if you can find what you are looking for.</p>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <a href="/">Back to Home</a>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
        <div className="empty_space"></div>
      </Body>
    </HelmetProvider>

  )
}


const Body = styled.div`
  background-color: #000;
  padding: 0 10%;
  text-align: center;
  @media (min-width: 768px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  >img{
    max-width: 100%;
  }
  >h1{
    color: white;
    font-family: Oswald,sans-serif;
    font-weight: 500;
    letter-spacing: .02em;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 1.1em;
    margin-top: -30px;
  }
  >.whooops{
    color: #f6202c;
    font-family: Quentin,script;
    font-size: 40px;
    line-height: 35px;
  }
  >.paragraph{
    color: white;
  }
  >a{
    text-transform: uppercase;
    color: white;
    font-family: Oswald,sans-serif;
    line-height: 27px;
    letter-spacing: .15em;
    font-weight: 500;
    border: 2px solid transparent;
    border-radius: 3px;
    background-color: #0a0a0a;
    padding: 15px 34px;

  }
`

export default NotFound